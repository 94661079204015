import React from 'react';
import { PrismicImage } from '../../utils/queries/types';
import Helmet from 'react-helmet';

const AmazeeIoMetadata = ({ page_meta_description, page_meta_thumbnail, location, title }: { title: string, page_meta_description?: string, page_meta_thumbnail?: PrismicImage, location: Location }) => {
    const defaultPageMetaDescription = 'Your open source PaaS: A developer-centric application delivery platform that manages infrastructure & operations, so developers don’t have to.';
    const defaultImageUrl = `http://amazee.io/images/social-share-image.jpg`;
    const t = `${title}`;

    const image = (page_meta_thumbnail && page_meta_thumbnail.url ? (
        page_meta_thumbnail.url.startsWith('/') ? `http://amazee.io${page_meta_thumbnail.url}` : page_meta_thumbnail.url
    ) : defaultImageUrl).replace('https://', 'http://');

    return (
        <Helmet>
            <style>
            {`
                * {
                    font-family: 'Heebo', 'Arial', 'arial', sans-serif;
                }
            `}
            </style>

            {/*<!-- Primary Meta Tags -->*/}
            <meta data-rh="true" name="robots" content="index,follow"/>
            <meta name="title" content={t} />
            <meta name="description" content={page_meta_description || defaultPageMetaDescription} />

            {/*<!-- Open Graph -->*/}
            <meta property="og:type" content="website" />
            <meta content="text/html; charset=UTF-8" name="Content-Type" />
            <meta property="og:url" content={location.href} />
            <meta property="og:title" content={t} />
            <meta property="og:description" content={page_meta_description || defaultPageMetaDescription} />
            <meta property="og:image" content={image} />
            <meta property="og:image:src" content={image} />
            <meta property="og:site_name" content="amazee.io" />

            {/*<!-- Twitter tags -->*/}
            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content={location.href} />
            <meta property="twitter:domain" content={location.host} />
            <meta property="twitter:description" content={page_meta_description || defaultPageMetaDescription} />
            <meta property="twitter:title" content={t} />
            <meta property="twitter:site" content="@amazeeio"/>
            <meta property="twitter:image" content={image} />
            <meta property="twitter:image:src" content={image} />
        </Helmet>
    )
}

export default AmazeeIoMetadata;